.required {
  &:after {
    content: "*";
    position: relative;
    font-size: inherit;
    color: $red;
    padding-left: 0.25rem;
    font-weight: $font-weight-bold;
  }
}

.css-13cymwt-control {
  min-height: 43px !important;
  border-radius: 0.375rem !important;
  
}
.css-t3ipsp-control {
  border-color: $input-focus-border-color !important;
  box-shadow: none !important;
}
