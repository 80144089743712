.bg-gradient-info-new {
    background: linear-gradient(87deg, #9a2f26 0, #9a2f26 100%) !important;
    background-image: linear-gradient(87deg, rgb(154, 47, 38) 0px, rgb(154, 47, 38) 100%) !important;
    background-position-x: initial !important;
    background-position-y: initial !important;
    background-size: initial !important;
    background-repeat: initial !important;
    background-attachment: initial !important;
    background-origin: initial !important;
    background-clip: initial !important;
    background-color: initial !important;
} 

/* .ant-switch-checked {
    background: #e62a2e !important;
}

.Mui-checked, .MuiCheckbox-indeterminate {
    color: #e62a2e !important;
} */

.primary-text-color, a {
    /* color: #e62a2e !important; */
    color: #5e72e4 !important;
}

.btn-primary {
    background-color: #5e72e4 !important;
    border-color: #5e72e4 !important;
    color: #ffffff !important;
}

.custom-toggle input:checked + .custom-toggle-slider {
    border: 1px solid #5e72e4 !important;
}

.custom-toggle input:checked + .custom-toggle-slider:before {
    background: #5e72e4 !important;
}

a.nav-link {
    color: #e62a2e !important;
}

.main-bg-color {
    background:#9a2f26 !important;
}

.main-bg2 {
    background-color: #e62a2e !important;
}

/* .model-bg-color {
    background-color: #e62a2e !important;
} */

/* .model-border-color:hover {
    border-color: #e62a2e !important;
    color: #e62a2e !important;
} */

/* .pagination-border-color {
    border-color: #e62a2e !important;
} */

/* .ant-pagination-item-active {
    border-color: #e62a2e !important;
} */

/* input[type="radio"], input[type="checkbox"] {
    accent-color: #e62a2e !important;
} */

.cursor-pointer{
    cursor: pointer;
}
