.img-btn {
  box-shadow: 0px 9px 16px 0px rgb(24 28 50 / 25%) !important;
  border-radius: 50%;
  position: absolute;
  background-color: var(--kt-light);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 1px solid var(--kt-gray-400);
  transition: all 0.5s ease;
}
.close-btn {
  cursor: pointer;
  height: 24px;
  width: 24px;
  right: -4px;
  top: -4px;
}
.img-box {
  position: relative;
  display: inline-block;
  border-radius: 0.42rem;
  background-repeat: no-repeat;
  background-size: cover;
}
.img-wrapper {
  width: 100px;
  height: 100px;
  border-radius: 0.42rem;
  background-repeat: no-repeat;
  background-size: cover;
  border: 3px solid var(--kt-light);
  box-shadow: 0 0.5rem 1.5rem 0.5rem rgb(0 0 0 / 8%);
}
.box-shadow {
  box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075) !important;
  border: 3px solid var(--kt-gray-400);
}

.fp-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #00000061;
}

.fp-container .fp-loader {
  top: 435px;
  left: 47%;
  z-index: 1000;
  position: absolute;
}

.action {
  position: sticky;
  right: 0;
  z-index: 1;
  background: white;
}
.word_wrap{
  white-space: normal !important;
  min-width: 200px;
}
.description_wrap{
  white-space: normal !important;
  max-width: 200px;
}
.link {
  overflow-x: hidden;
  overflow-y: hidden;
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap; 
}   
.cover_image {
  // height: 100px;
  width: 100px;
}
@media (max-width: 320px){
  :where(.css-dev-only-do-not-override-1okl62o).ant-pagination.ant-pagination-mini .ant-pagination-item {
    min-width: 29px !important;
  }
  :where(.css-dev-only-do-not-override-1okl62o).ant-pagination .ant-pagination-item a { 
    padding: 0px;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .add_btn {
    text-align: left !important;
    margin-top: 15px !important;
    padding-right: 0px !important;
  }
  .header {
    padding-bottom: 5rem !important;
  }
}
@media (min-width: 320px) and (max-width: 1024px) {
  .filter {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
  }
}
@media screen and (max-width: 320px) {
  .filter_btn {
    margin-top: 10px !important;
  }
}
@media (width: 1024px) {
  .view_cards {
    width: 230px;
  }
  .columns {
    display: none;
  }
  .detail_card {
    width: 385px;
  }
  .col-lg-3 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.modal-content {
  height: 100% !important;
  width: 100% !important;
}
.dot_border {
  border-bottom: 1px dotted #e9dede;
}
.dashboard_Header {
  padding-bottom: 16rem !important;
}
.delete-model {
  width: 50% !important;
}